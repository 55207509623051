<template>
  <div class="ai-select">
    <div class="ask-box">
      <i class="del-icon el-icon-circle-close" @click="closeSelect"></i>
      <div class="loader"></div>
      <div class="question">
        <p>您的农场</p>
        <p>地块是？</p>
      </div>
      <!-- <div ref="loginTimes" class="words-cloud"></div> -->
    </div>
    <div class="change-button" @click="changeLand">
      <span>请选择&gt;</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "aiLandSelect",
  props: {
    isLandSelect: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closeSelect() {
      this.$emit("closeLandSelect");
    },
    changeLand() {
      this.$emit("changeLand");
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes loader-rotate {
  from {
    transform: rotateX(63deg) rotate(0deg);
  }
  to {
    transform: rotateX(63deg) rotate(-360deg);
  }
}
.ai-select {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.78);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ask-box {
    width: 699px;
    height: 574px;
    // margin-bottom: 60px;
    position: relative;
    background: url("../../../assets/image/ai/select/main_bg.png") center center
      no-repeat;
    background-size: cover;
    .words-cloud {
      width: 60%;
      height: 60%;
      position: absolute;
      top: 6%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 11;
    }
    .del-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      color: #707070;
      font-size: 38px;
      &:hover {
        color: #fff;
      }
    }
    .loader {
      position: relative;
      top: 24%;
      z-index: 10;
      left: 18%;
      width: 460px;
      height: 460px;
      // background: linear-gradient(to top, #31CEDF 1%, #001d30 2%);
      // background: linear-gradient(to top, #31CEDF 1%, transparent 2%);
      border-radius: 50%;
      animation: loader-rotate 3s linear infinite;
      transform: rotateX(63deg);
      transform-style: preserve-3d;
      &::before {
        content: "";
        position: absolute;
        background: transparent;
        // background: #001d30;
        inset: 4px 4px 0 0;
        border-radius: 50%;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        right: 202px;
        width: 6px;
        height: 6px;
        background: #31cedf;
        border-radius: 50%;
        box-shadow: 0 0 12px #31cedf;
      }
    }
    .question {
      width: 383px;
      font-size: 48px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #eff8fc;
      text-align: center;
      position: absolute;
      top: 26%;
      left: 50%;
      z-index: 10;
      transform: translateX(-50%);
      background: linear-gradient(
        0deg,
        #a6d2ff 0%,
        #e9f8ff 73.3154296875%,
        #ffffff 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      p {
        text-align: center;
      }
    }
  }
  .change-button {
    width: 281px;
    height: 127px;
    background: url("../../../assets/image/ai/select/button_bg.png") center
      center no-repeat;
    background-size: cover;
    font-size: 48px;
    // font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: #fff;
    line-height: 107px;
    text-align: center;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    span {
      font-family: YouSheBiaoTiHei;
    }
  }
}
</style>
